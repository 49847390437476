<template>
  <!-- 虚拟仿真实验详情 -->
  <div class="main-contain">
    <div class="head">
      <div class="head-title">问答讨论 / 问答讨论详情</div>
      <div class="returnbt">
        <div class="bt" @click="toback">返回</div>
      </div>
    </div>
    <div class="line"></div>
    <div class="item">
      <div class="replybt" style="display: flex;justify-content: flex-end;margin-right:10px">
        <el-button type="primary" class="bt btactive" @click="replybt">回复</el-button>
      </div>
      <div style="display: flex;align-self:center;">
        <div class="title">{{ item.title }}</div>
        <div class="item-two">
          <div v-if="item.is_top === 1" class="item-two-bt item-two-bt-active">置顶</div>
          <div v-if="item.is_teacher_join === 1" class="item-two-bt">教师参与</div>
          <!-- <div v-if="item.has_publish === 1" class="item-two-bt item-two-bt-active">已发布</div> -->
          <div v-if="Number(item.has_publish) === 0" class="item-two-bt item-two-bt-active-1">待审核</div>
        </div>
      </div>
      <div class="text-contain">
        <div class="text">{{ item.content }}</div>
      </div>
      <div class="bottom-contain">
        <div class="bottom-left">
          <el-image class="elimage" :src="item.avatar">
            <template slot="error">
              <el-image :src="avater1"></el-image>
            </template>
          </el-image>
          <div class="teach">{{ item.author_name }}</div>
          <div class="time">{{ item.created_at | dataformat }}</div>
          <div class="time">发布</div>
        </div>
        <div class="bottom-right" v-if="Number(item.has_publish) === 1">
          <div class="bottom-right-item" @click="setParentLike(item)">
            <template v-if="item.like_status === 0">
              <el-image class="elimage1 elimage-icons" :src="icon2" />
              <div class="num">{{ item.like_count }}</div>
            </template>
            <template v-else-if="item.like_status === 1">
              <el-image class="elimage1 elimage-icons" :src="icon5" />
              <div class="num">{{ item.like_count }}</div>
            </template>
          </div>
          <div class="line2"></div>
          <div class="bottom-right-item">
            <el-image class="elimage2 elimage-icons" :src="icon1" />
            <div class="num">{{ item.view_count }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="totaltitle">共{{ total }}条回复</div>
    <div class="replycontain">
      <div class="replyitem" v-for="(item, index) in replylist" :key="index">
        <div class="itemup">
          <el-image class="elimage" :src="item.avatar">
            <template slot="error">
              <el-image :src="avater1"></el-image>
            </template>
          </el-image>
          <div class="nametext">{{ item.user_name }}</div>
          <div class="timetext">{{ item.created_at | dataformat }}</div>
          <div class="item-two">
            <!-- <div v-if="Number(item.has_publish) === 1" class="item-two-bt item-two-bt-active">已发布</div> -->
            <div v-if="Number(item.has_publish) === 0" class="item-two-bt item-two-bt-active-1">待审核</div>
          </div>
        </div>
        <div class="itembottom">
          <div class="left">
            <div v-html="item.content"></div>
          </div>
          <div class="right">
            <template v-if="Number(item.has_publish) === 1">
              <div class="bottom-right-item" @click="setlike(item)">
                <template v-if="item.like_status === 0">
                  <el-image class="elimage1 elimage-icons" :src="icon2" />
                  <div class="num">{{ item.like_count }}</div>
                </template>
                <template v-else-if="item.like_status === 1">
                  <el-image class="elimage1 elimage-icons" :src="icon5" />
                  <div class="num">{{ item.like_count }}</div>
                </template>
              </div>
              <div class="line2"></div>
              <div class="bottom-right-item" @click="replychildbt(item)">
                <el-image class="elimage3 elimage-icons" :src="icon3" />
                <div class="num">{{ item.reply_count }}</div>
              </div>
              <div class="line2" v-if="item.is_mine == 1"></div>
            </template>
            <div class="bottom-right-item" @click="deletreply(item)" v-if="item.is_mine == 1">
              <el-image class="elimage3 elimage-icons" :src="icon4" />
            </div>
          </div>
        </div>
        <div class="replychild">
          <div class="replyitem" v-for="(item, index) in item.child" :key="index">
            <div class="itemup">
              <template v-if="!item.to_user_name">
                <el-image class="elimage" :src="item.avatar">
                  <template slot="error">
                    <el-image :src="avater1"></el-image>
                  </template>
                </el-image>
                <div class="nametext">{{ item.user_name }}</div>
              </template>
              <template v-else>
                <el-image class="elimage" :src="item.avatar">
                  <template slot="error">
                    <el-image :src="avater1"></el-image>
                  </template>
                </el-image>
                <div class="nametext">
                  {{ item.user_name }}&nbsp;&nbsp;&nbsp;&nbsp;回复&nbsp;&nbsp;&nbsp;&nbsp;
                  {{ item.to_user_name }}
                </div>
              </template>
              <div class="timetext">{{ item.created_at | dataformat }}</div>
              <div class="item-two">
                <!-- <div v-if="Number(item.has_publish) === 1" class="item-two-bt item-two-bt-active">已发布</div> -->
                <div
                  v-if="Number(item.has_publish) === 0"
                  class="item-two-bt item-two-bt-active-1"
                >待审核</div>
              </div>
            </div>
            <div class="itembottom">
              <div class="left">
                <div style="margin: 15px 10px 20px 0px;flex: 0 0 auto; color: rgb(103, 111, 117);">
                  <span>{{ '@' + item.user_name }}</span>
                </div>

                <div v-html="item.content"></div>
              </div>
              <div class="right">
                <template v-if="Number(item.has_publish) === 1">
                  <div class="bottom-right-item" @click="setlike(item)">
                    <el-image
                      class="elimage1 elimage-icons"
                      :src="item.like_status ? icon5 : icon2"
                    />
                    <div class="num">{{ item.like_count }}</div>
                  </div>
                  <div class="line2"></div>
                  <div class="bottom-right-item" @click="childreply(item)">
                    <el-image class="elimage3 elimage-icons" :src="icon3" />
                    <div class="num">{{ item.reply_count }}</div>
                  </div>
                </template>
                <div class="line2" v-if="item.is_mine == 1"></div>
                <div
                  class="bottom-right-item"
                  @click="deletchildreply(item)"
                  v-if="item.is_mine == 1"
                >
                  <el-image class="elimage3 elimage-icons" :src="icon4" />
                </div>
              </div>
            </div>

            <div :class="index % 2 === 0 ? 'line3' : ''"></div>
          </div>
        </div>
        <div :class="index % 2 === 0 ? 'line3' : ''"></div>
      </div>
      <div class="elpagination">
        <div class="elpagination-title">共{{ total }}条</div>
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          :page-size="10"
          :current-page="queryParams.page"
          @current-change="pageChange"
        ></el-pagination>
      </div>
    </div>

    <Customdialog ref="customdialog" title="发表评论">
      <div slot="dialogbody" class="dialogbody">
        <Tinymce
          ref="editor"
          v-model="queryParams.content"
          style="height: auto; border-radius: 22px"
          @setInput="getValue"
        ></Tinymce>
      </div>
      <div slot="dialogfooter" class="dialogfooter">
        <div class="bt" @click="canclebt">取消</div>
        <div class="bt btactive" @click="submitbt">确定</div>
      </div>
    </Customdialog>
  </div>
</template>
<script>
import { autoImgCorrection } from "@/utils/index"
import Customdialog from "@/components/customdialog.vue"
import { setQaLike } from "@/api/qa"
import {
  getQaReplyList,
  addQaReplyAdd,
  setQaReplyLike,
  QaReplyDelete,
  qareply_deatil
} from "@/api/qareply"
import dayjs from "dayjs"
import Tinymce from "@/components/VueTinymce/index.vue"

export default {
  name: "courselearning",
  components: { Customdialog, Tinymce },
  data () {
    return {
      dialogVisible: false,
      testimage: require("@/assets/test.png"),
      icon4: require("@/assets/shanchu.png"),
      avater1: require("@/assets/学生.png"),
      icon1: require("@/assets/查看_d.png"),
      icon5: require("@/assets/点赞_c.png"),
      icon2: require("@/assets/点赞_d.png"),
      icon3: require("@/assets/评论_d.png"),
      icon4: require("@/assets/删除_d.png"),
      item: "",
      replylist: [],
      total: 0,
      queryParams: {
        qa_id: '', // 问答ID
        qa_reply_id: '', // 被回复ID，回复楼层和楼层回复此参数必传
        content: '', // 回复内容
        page: 1,
        per_page: 10
      },
      loading: null,
    }
  },
  filters: {
    dataformat: function (value) {
      if (!value) return ""
      return dayjs.unix(value).format("YYYY-MM-DD HH:mm:ss")
    },
  },
  created () {
    // let str = decodeURIComponent(this.$route.query.item);
    // this.item = JSON.parse(str);
    // this.queryParams.qa_id = this.item.id
    this.queryParams.qa_id = this.$route.query.id
    this.getqareplylist({ qa_id: this.queryParams.qa_id })
    this.initInfo()
  },
  methods: {
    // 主体详情
    initInfo () {
      qareply_deatil({ id: this.queryParams.qa_id }).then((res) => {
        if (res.code === 0) {
          if (this.loading) {
            this.loading.close()
          }
          this.item = res.data
        }
      }).catch(() => {
        if (this.loading) {
          this.loading.close()
        }
      })
    },
    // 二级回复
    replychildbt (item) {
      this.queryParams.qa_reply_id = item.id
      this.queryParams.content = ""
      this.$refs.customdialog.dialogopenbt()
    },
    getqareplylist (params) {
      this.replylist = []
      getQaReplyList(params)
        .then((response) => {
          if (response.data.data && response.data.data.length > 0) {
            this.total = response.data.total
            let resData = response.data.data
            if (resData && resData.length > 0) {
              resData.forEach(item => {
                item.content = autoImgCorrection(item.content)
                if (item.child.length > 0) {
                  item.child.forEach(el => {
                    el.content = autoImgCorrection(el.content)
                  })
                }
              })
            }
            this.replylist = resData
          }
        })
        .catch((error) => {
          //console.log(error);
        })
    },
    pageChange (val) {
      this.queryParams.page = val
      this.getqareplylist(this.queryParams)
    },
    toback () {
      this.$router.back(-1)
    },
    replybt () {
      this.$refs.customdialog.dialogopenbt()
      this.queryParams.qa_reply_id = ''
      this.queryParams.content = ""
    },
    // 回复
    childreply (item) {
      this.$refs.customdialog.dialogopenbt()
      this.queryParams.qa_reply_id = item.id
      this.queryParams.content = ""
    },
    setParentLike (data) {
      this.loading = this.$loading({
        lock: true,
        text: "",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.4)",
        customClass: 'setlike-loading',
      })
      this.item.like_status = this.item.like_status === 1 ? 0 : 1
      this.item.like_count = this.item.like_status ? this.item.like_count + 1 : this.item.like_count - 1
      this.setQaLike({ id: data.id })
    },
    setQaLike (params) {
      setQaLike(params)
        .then((response) => {
          if (response.code === 0) {
            this.initInfo()
          }
        })
        .catch((error) => { })
    },
    setlike (item) {
      this.setQaReplyLike({
        id: item.id,
      })
    },
    setQaReplyLike (params) {
      setQaReplyLike(params)
        .then((response) => {
          if (response.code === 0) {
            setTimeout(() => {
              this.getqareplylist({ qa_id: this.item.id })
            }, 1500)
          }
        })
        .catch((error) => {
          //console.log(error);
        })
    },
    deletreply (item) {
      this.$confirm("此操作将永久删除该评论, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.QaReplyDelete({
            id: item.id,
          })
          this.$message({
            type: "success",
            message: "删除成功!",
          })
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          })
        })
    },
    deletchildreply (item) {
      this.$confirm("此操作将永久删除该评论, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.QaReplyDelete({
            id: item.id,
          })
          this.$message({
            type: "success",
            message: "删除成功!",
          })
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          })
        })
    },
    QaReplyDelete (params) {
      QaReplyDelete(params)
        .then((response) => {
          if (response.code === 0) {
            this.$message({
              message: '删除成功',
              type: "success",
            }
            )
            this.getqareplylist({ qa_id: this.item.id })
          }
        })
        .catch((error) => {
          //console.log(error);
        })
    },
    canclebt () {
      this.$refs.customdialog.dialogclosebt()
    },
    submitbt () {
      this.addQaReplyAdd()
      this.$refs.customdialog.dialogclosebt()
    },

    addQaReplyAdd () {
      addQaReplyAdd(this.queryParams)
        .then((response) => {
          if (response.code === 0) {
            this.getqareplylist({ qa_id: this.item.id })
          }
        })
        .catch((error) => {
          //console.log(error);
        })
    },
    // 获取富文本得值
    getValue (data) {
      this.queryParams.content = data
    }
  },
};
</script>
<style scoped lang="scss">
.main-contain {
  border: 1px solid transparent;
  margin-bottom: 20px;
  background: #f7f7f7;
  min-height: calc(100vh - 240px);
  width: 1440px;

  .head {
    background: #ffffff;
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 20px;
    padding-top: 10px;
    height: 40px;
    border-bottom: 2px solid #cccccc;
    padding-bottom: 10px;
    padding-left: 20px;

    .head-title {
      font-size: 20px;
      font-weight: 400;
      color: #3d84ff;
      user-select: none;
      white-space: nowrap;
    }
  }

  .returnbt {
    margin-top: 10px;
    margin-right: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .bt {
      width: 64px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      background: #3d84ff;
      border-radius: 4px;
      color: white;
      user-select: none;
      cursor: pointer;
      margin-bottom: 10px;
      font-size: 14px;
    }
  }

  .item {
    padding-top: 20px;
    padding-left: 30px;
    margin-left: 20px;
    min-height: 180px;
    background: #ffffff;

    .title {
      font-size: 20px;
      font-weight: 400;
      color: #222222;

      .replybt {
        margin-right: 10px;
        width: 64px;
        height: 38px;
        line-height: 38px;
        text-align: center;
        background: #3d84ff;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
        user-select: none;
        cursor: pointer;
        float: right;
      }
    }

    .item-two {
      // margin-top: 6px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .item-two-bt {
        width: 60px;
        min-width: 60px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        padding: 0 2px;
        font-family: PingFang SC;
        font-size: 12px;
        font-weight: 400;
        color: #409eff;
        background: #ffffff;
        border: 1px solid #409eff;
        border-radius: 4px;
        margin-top: 3px;
        margin-left: 10px;
      }

      .item-two-bt-active {
        color: #0fbf7f;
        width: 40px;
        min-width: 40px;
        border: 1px solid #0fbf7f;
      }

      .item-two-bt-active-1 {
        color: #f13218;
        width: 40px;
        min-width: 40px;
        border: 1px solid #f13218;
      }
    }

    .text-contain {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .text {
        margin-top: 10px;
        font-size: 15px;

        font-weight: 400;
        color: #222222;
      }
    }

    .bottom-contain {
      margin-top: 20px;
      padding-bottom: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .bottom-left {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .elimage {
          width: 30px;
          height: 30px;
          border-radius: 50%;
        }

        .teach {
          margin-left: 10px;
          margin-right: 10px;
          font-size: 15px;

          font-weight: 400;
          color: #222222;
        }

        .time {
          margin-right: 10px;
          font-size: 12px;

          font-weight: 400;
          color: #a0a0a0;
        }
      }

      .bottom-right {
        padding-right: 30px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .bottom-right-item {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          cursor: pointer;

          .elimage2 {
            width: 22px;
            height: 16px;
          }

          .elimage1 {
            width: 20px;
            height: 20px;
            margin-bottom: 4px;
          }

          .elimage3 {
            width: 19px;
            height: 16px;
          }

          .num {
            margin-left: 6px;
            font-size: 15px;

            font-weight: 400;
            color: #a0a0a0;
          }
        }

        .line2 {
          margin-left: 20px;
          margin-right: 20px;
          height: 15px;
          border-right: 2px solid #e8e8e8;
        }
      }
    }

    .bt {
      // margin-right: 20px;
      width: 84px;
      height: 38px;
      // line-height: 38px;
      text-align: center;
      background: #ffffff;
      border: 1px solid #cccccc;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 400;
      color: #a0a0a0;
      user-select: none;
      cursor: pointer;
    }

    .btactive {
      background: #3d84ff;
      border: 1px solid #3d84ff;
      color: white;
    }
  }

  .totaltitle {
    margin-left: 30px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 15px;

    font-weight: 400;
    color: #222222;
  }

  .replycontain {
    min-height: 200px;
    box-sizing: border-box;
    background: #ffffff;
    padding-top: 20px;
    margin-left: 20px;
    margin-bottom: 20px;

    .replyitem {
      margin-bottom: 20px;
      margin-left: 40px;
      margin-right: 20px;

      .itemup {
        height: 40px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .elimage {
          width: 30px;
          height: 30px;
          border-radius: 50%;
        }

        .nametext {
          margin-right: 10px;
          margin-left: 10px;
          font-size: 15px;
          font-weight: 400;
          color: #222222;
        }

        .timetext {
          font-size: 12px;
          font-weight: 400;
          color: #a0a0a0;
        }

        .item-two {
          // margin-top: 6px;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .item-two-bt {
            width: 60px;
            min-width: 60px;
            height: 20px;
            line-height: 20px;
            text-align: center;
            padding: 0 2px;
            font-family: PingFang SC;
            font-size: 12px;
            font-weight: 400;
            color: #409eff;
            background: #ffffff;
            border: 1px solid #409eff;
            border-radius: 4px;
            margin-top: 3px;
            margin-left: 10px;
          }

          .item-two-bt-active {
            color: #0fbf7f;
            width: 40px;
            min-width: 40px;
            border: 1px solid #0fbf7f;
          }

          .item-two-bt-active-1 {
            color: #f13218;
            width: 40px;
            min-width: 40px;
            border: 1px solid #f13218;
          }
        }
      }

      .itembottom {
        // margin-top: 6px;
        // display: flex;
        // justify-content: space-between;
        align-items: center;

        .left {
          font-size: 15px;

          font-weight: 400;
          color: #222222;
          display: flex;
        }

        .right {
          padding-right: 30px;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          // margin-top: 45px;

          .bottom-right-item {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            cursor: pointer;

            .elimage1 {
              width: 20px;
              height: 20px;
            }

            .elimage2 {
              width: 19px;
              height: 21px;
              margin-bottom: 4px;
            }

            .elimage3 {
              width: 19px;
              height: 16px;
            }

            .num {
              margin-left: 6px;
              font-size: 15px;

              font-weight: 400;
              color: #a0a0a0;
            }
          }

          .line2 {
            margin-left: 20px;
            margin-right: 20px;
            height: 15px;
            border-right: 2px solid #e8e8e8;
          }
        }
      }

      .line3 {
        margin-top: 15px;
        border-bottom: 1px solid #e8e8e8;
      }

      .replychild {
        margin-right: 20px;
        // background: #f7f7f7;
      }
    }
  }

  .dialogbody {
    padding: 20px;
  }

  .dialogfooter {
    padding-right: 40px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .bt {
      margin-right: 20px;
      width: 64px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      background: #ffffff;
      border: 1px solid #cccccc;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 400;
      color: #a0a0a0;
      user-select: none;
      cursor: pointer;
    }

    .btactive {
      background: #3d84ff;
      border: 1px solid #3d84ff;
      color: white;
    }
  }
}

.elpagination {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  padding-right: 20px;
  align-items: center;
  padding-bottom: 20px;

  .elpagination-title {
    margin-right: 4px;
  }
}
.elimage-icons {
  width: 20px !important;
  height: 20px !important;
}
</style>
